
.navBarIMg{
    width:190px;
    cursor:pointer;
    margin-left:2rem;
    margin-top:.2rem;
  }

  
  @media only screen and (max-device-width:767px){
    .navBarIMg{
      width: 180px;
      margin-top: -0.5rem;
      margin-left: -.5rem;
    }
  
  }
  
  @media only screen and (min-device-width:1280px){
    .Humburger{
      display: none;
    }
  }
  
  
  @media only screen and (max-device-width:767px){
    .Humburger{
      display: flex;
    }
  }


  @media (max-width:480px) {
    #navbar{
      display: none;
    }

    .Humburger{
      /* display: none; */
    }
    
  }

  
@tailwind base;
@tailwind components;
@tailwind utilities;

/* .slick-dots {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    margin: 0 5px;
  } */


  .customDot {
    width: 10px;
    height: 10px;
    background-color: rgb(169, 169, 169);
    border-radius: 50%;
    margin-top: 0.1rem;
    transition: background-color 0.3s ease;
    text-align: center;
}

.lg-moveup-cptraining{
  transform: translateY(2.2%);
  margin-bottom: 4rem;
}

.lg-moveup-consulting{
  transform: translateY(2%);
  margin-bottom: 2rem;
}

/* .heroMobileHeight{
  border-radius: 1000px;
} */

.borderRadiusImg{
  /* border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px; */
}


.lg-moveup-contact{
  transform: translateY(-34%);
  margin-bottom: -10rem;
}

.heroMobileHeightContact{
  transform: translateY(-27%);
}


.blackBg {
  background-color: black;
}

.heroImageDiv{
    height: 27rem;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
}

.mainBigAppDiv{
  font-family: 'Montserrat-classic', sans-serif;
}

.headingText{
  transform: translateX(26%);
}

.lg-moveup-About{
  transform: translateY(12%);
  margin-bottom:5rem;
}

@media (min-width:1300px) {
  .head-text{
    font-size: 50px;
    line-height: 4rem;
  }

  /* .faqMoveUp{
    transform: translateY(0);
  } */

  .lg-moveup-About{
    transform: translateY(15%);
    margin-bottom:8rem;
  }

  .lg-moveup-consulting{
    transform: translateY(5%);
  }

  .lg-moveup-cptraining{
    transform: translateY(4.2%);
    margin-bottom: 5rem;
  }

  .lg-moveup-contact{
    transform: translateY(-60%);
    margin-bottom: -19rem;
  }

  .aboutusImg{
    width: 500px;
    height:500px;
  }

  .aboutUstext{
    font-size: 20px;
  }

  .heroImageDiv{
    height: 42rem;
}

.bigScreenPadding{
  padding-left: 8rem;
  padding-right: 8rem;
}

}

@media (max-width:768px) {
  
  .heroMobileHeight{
    height:24rem;
    border-radius: 50px;
  }
  
  .emailForMobileFooter{
    transform: translate(0,0);
  }
  
  .head-text{
    font-size: 24px;
    width: auto;
  }

  .aboutUsImgDiv{
    transform: translateY(-13%);
  }

  .aboutusImg{
    width: 300px;
    height:300px;
  }

  .headingText{
    transform: translate(1%,-20%);
    margin-left: 1rem;
    padding:10px;
  }

  .headingTextTraining{
    transform: translateX(10%);
  }

  .border-small{
    border: none;
  }

  .productHeading{
    font-size: 30px;
  }

  .productSubHeading{
    font-size: 16px;
  }

  .productSolutions{
    margin-top: 2rem;
  }

  /* .educationIntro{
    padding: 0;
  } */

  .mobileCpTrainingFlex{
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .cpHeadPara1{
    width: 100%;
    text-align: left;
  }

  .cpHeadPara2{
    width: 100%;
    margin: 0;
    text-align: left;
  }

  .BlockChainServiceCard{
    margin-top:0rem;
  }

  .contactUsFooter{
    margin-left: 0;
  }

  .lg-moveup-contact{
    transform: translateY(-18%);
  }

  .heroImageDiv{
    height: 8rem;
    /* transform: translateY(50%); */
    margin-top: 4rem;
    z-index: 1000;
}

.smallScreenFont{
  font-size: 10px;
  padding: 4px;
}

.smallScreenWidth{
  padding: 10;
  width:95%;
}

  .lg-moveup-About{
  transform: translateY(4%);
  margin-bottom: 1rem;
}

.lg-moveup-cptraining{
  transform: translateY(0.5%);
  margin-bottom: 3rem;
}

.lg-moveup-consulting{
  transform: translateY(0.5%);
  margin-bottom: 1rem;
}

.portFolioHeadingMobileFont{
  font-size: 18px;
  line-height: 25px;
}

.portFolioSubHeadingMobileFont{
  font-size: 12px;
  line-height: 20px;
}

.portFolioPointsMobileFont{
  font-size: 11px;
  line-height: 15px;
  text-align: start;
  padding-right: 0.5rem;
}

.trainingHeadingMobileFont{
  font-size:30px;
  text-align: left;
}

.trainingSubHeadingMobileFont{
  font-size:15px;
}

.serviceHeadingMobileFont{
  font-size: 30px;
}
.bannerFontMobile{
  font-size: 10px;
}

.desktop-br{
  display: none;
}

}



.customDot:hover,
.customDot.active {
    background-color: rgb(135, 206, 235);
}
  